var SHIIP = SHIIP || {};
SHIIP.login = SHIIP.login || {};

(function () {

    function LoginController() {
        this._init();
    }

    LoginController.prototype = {

        _init: function( config ) {
            var me = this;
            $.extend(me, config);
            me.initEvents();
        },

        initEvents : function() {
            $("form[name='login']").on('submit', function() {
                $(this).find("button[name='submit']").prop('disabled', true);
            });
        }
    };

    SHIIP.login.LoginController = LoginController;
})();

var loginController = null;
$(function(){
    loginController = new SHIIP.login.LoginController({});
});